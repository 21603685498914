<template>
  <div class="frais-commercial-cactus">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-1">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-1">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-1">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2" v-if="computedRole">
          <div class="label-box-style  w-70-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterType"
            :options="[{ value: null, text: 'Tous' }, ...computedListType]"
            text-field="text"
            value-field="value"
            @change="handlechangeFilialeSupport"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterType != null">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterStatut"
            :options="[
              { full_name: 'Tous', valus: null },
              ...computedValidationList
            ]"
            text-field="full_name"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-60-px">
            <span class="title-tabel">Super Régie</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterSuperRegie"
            :options="[{ value: null, full_name: 'Tous' }, ...RegieList]"
            text-field="full_name"
            value-field="value"
            @change="handleFilterSuperregie"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Régie</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterRegie"
            :options="[
              { value: null, text: 'Tous' },
              ...getRegieOnlineInCactusFrais
            ]"
            text-field="text"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <div
          v-if="getLoadingFraisBddCommericalCactus"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <global-validation-cactus
            v-if="
              cantCreate($options.name) &&
                !isSuperAdmin &&
                filterType != null &&
                computedCheckedRows.length
            "
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @reloadData="reloadData"
            :dataToUse="computedFraisBddCommericalCactus"
          />
          <generation-facture-avoir-frais
            :interfaceName="$options.name"
            :filterType="filterType"
            :dataToUse="computedFraisBddCommericalCactus"
            curentInterface="cactus"
            @reloadData="reloadData"
          />
          <validation-statue-installateur
            v-if="
              cantUpdate(this.$options.name) &&
                filterType &&
                computedCheckedRows.length
            "
            :filterType="filterType"
            :dataToUse="computedFraisBddCommericalCactus"
            @reloadData="reloadData"
            curentInterface="cactus"
          />
          <extraction-cactus
            v-if="cantExport(this.$options.name)"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedFraisBddCommericalCactus"
            :filter="ComputedFilter"
            :selected="computedCheckedRows.length"
          />
          <div v-if="getLoadingMail" class="chargement chargement-loading-icon">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-if="
              !getLoadingMail &&
                computedCheckedRows &&
                computedCheckedRows.length > 0
            "
          >
            <sendMailCommercialCactus
              :type="filterType"
              :dataToUse="computedFraisBddCommericalCactus"
              v-if="
                !isSuperAdmin &&
                  cantSend($options.name) &&
                  computedCheckedRows.length
              "
            />
          </div>
          <!-- <extraction-cactus-invalid
            v-if="cantExport(this.$options.name) && filterType"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
              :dataToUse="computedFraisBddCommericalCactus"

          /> -->
          <!-- <b-button
            v-if="cantRefresh(this.$options.name) && this.filterMonth != null"
            size="sm"
            class="button-export-style  ml-2"
            @click="refreshBddCommericalCactus"
            ><img src="@/assets/arrows_rotate.png"
          /></b-button> -->
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisBddCommericalCactus table-header"
          :items="computedFraisBddCommericalCactus"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          :sort-by.sync="sortBy"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template slot="thead-top" slot-scope="" v-if="isSuperAdmin">
            <tr>
              <th
                :colspan="
                  filterType == null ? 11 : filterType == 'CACTUS' ? 11 : 10
                "
              ></th>
              <th
                :colspan="1"
                v-if="filterType == null || filterType == 'CACTUS'"
              ></th>
              <th
                :colspan="1"
                v-if="filterType == null || filterType == 'CACTUS'"
              >
                <column-header-with-tool-tip
                  label="cactus"
                  column="commercial"
                  :dataToUse="computedFraisBddCommericalCactus"
                  @reloadData="reloadData"
                  :updateFunction="updateTauxFraisInInterface"
                />
              </th>
              <th
                :colspan="
                  filterType == null && fields[13].details == true ? 1 : 9
                "
                v-if="filterType == null || filterType == 'CACTUS'"
              ></th>

              <th
                :colspan="
                  filterType == null && fields[22].details == true ? 1 : 9
                "
                v-if="filterType == null || filterType == 'LAV'"
              >
                <column-header-with-tool-tip
                  label="externe"
                  column="externe_value"
                  :dataToUse="computedFraisBddCommericalCactus"
                  @reloadData="reloadData"
                  :updateFunction="updateTauxFraisInInterface"
                />
              </th>
              <th
                :colspan="
                  filterType == null && fields[31].details == true ? 1 : 9
                "
                v-if="
                  filterType == null || filterType == 'ALTEO FRANCE Supervision'
                "
              >
                <column-header-with-tool-tip
                  label="supervision"
                  column="alteo1_value"
                  :dataToUse="computedFraisBddCommericalCactus"
                  @reloadData="reloadData"
                  :updateFunction="updateTauxFraisInInterface"
                />
              </th>
              <th
                :colspan="
                  filterType == null && fields[40].details == true ? 1 : 9
                "
                v-if="
                  filterType == null ||
                    filterType == 'ALTEO FRANCE Support Commercial'
                "
              >
                <column-header-with-tool-tip
                  label="commercial"
                  column="commercial_value"
                  :dataToUse="computedFraisBddCommericalCactus"
                  @reloadData="reloadData"
                  :updateFunction="updateTauxFraisInInterface"
                />
              </th>
              <th
                :colspan="
                  filterType == null && fields[52].details == true ? 1 : 9
                "
                v-if="
                  filterType == null ||
                    filterType == 'ALTEO FRANCE Régie Commerciale INTERNE'
                "
              >
                <column-header-with-tool-tip
                  label="interne"
                  column="interne_value"
                  :dataToUse="computedFraisBddCommericalCactus"
                  @reloadData="reloadData"
                  :updateFunction="updateTauxFraisInInterface"
                />
              </th>
            </tr>
          </template>
          <template v-slot:head()="data">
            <div class="text-uppercase">
              <template v-if="filterType == null">
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="
                    data.field.details == false &&
                      computedHeaderVisibility(data.field.key)
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"/>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="
                    data.field.details == true &&
                      computedHeaderVisibility(data.field.key)
                  "
                  @click="setVisibilityFieldsHeader(data.field.key)"
              /></template>

              <span
                v-if="data.field.key != 'check_all'"
                :class="{
                  'white-color': computedHeaderVisibility(data.field.key)
                }"
              >
                {{ data.field.label }}</span
              >
              <div v-else>
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
              v-if="data.item.projects.length != 0"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus-h"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus-h"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template>
          <template v-slot:cell(numero_dossier)="">
            -
          </template>
          <template v-slot:cell(montant_alteo)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_supervision'
                })
              "
            >
              {{ data.item.alteo1 }}</span
            >
          </template>
          <template v-slot:cell(alteo_statue)=""> - </template>
          <template v-slot:cell(alteo_date)=""> - </template>
          <template v-slot:cell(installeur)="data">
            <span
              @click="handleDownloadExtraitInstalleur(data.item.name)"
              :title="'Télécharger extrait ' + data.item.name"
            >
              {{ data.item.name }}
            </span>
          </template>
          <template v-slot:cell(super_regie)=""> - </template>
          <template v-slot:cell(regie)=""> - </template>
          <template v-slot:cell(type_lead)=""> - </template>
          <template v-slot:cell(acompte)=""> - </template>
          <template v-slot:cell(montant_final)=""> - </template>
          <template v-slot:cell(agent_commercial_terrain)=""> - </template>
          <template v-slot:cell(agent_commercial)=""> - </template>

          <template v-slot:cell(montant_interne)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_interne'
                })
              "
            >
              {{ data.item.interne }}</span
            >
          </template>
          <template v-slot:cell(interne_statue)=""> - </template>
          <template v-slot:cell(interne_date)=""> - </template>
          <template v-slot:cell(montant_externe)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_externe'
                })
              "
            >
              {{ data.item.externe }}</span
            >
          </template>
          <template v-slot:cell(externe_statue)=""> - </template>
          <template v-slot:cell(externe_date)=""> - </template>
          <template v-slot:cell(montant_commercial)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_commercial'
                })
              "
            >
              {{ data.item.commercial }}
            </span>
          </template>
          <template v-slot:cell(cactus)="data">
            <span
              @click="
                handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial'
                })
              "
            >
              {{ data.item.cactus }}
            </span>
          </template>

          <template v-slot:cell(bonification_cactus)=""> - </template>
          <template v-slot:cell(coef_cactus)=""> - </template>
          <template v-slot:cell(commercial_statue)=""> - </template>
          <template v-slot:cell(commercial_date)=""> - </template>
          <!-- <template v-slot:cell(montant_alteo1)="data">
            {{ data.item.alteo1 }}
          </template>
          <template v-slot:cell(montant_alteo2)="data">
            {{ data.item.alteo2 }}
          </template> -->
          <template #row-details="data">
            <div v-if="data.item.loadingDetailt">
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </div>
            <span
              v-else
              v-for="(item, index) in data.item.projects"
              :key="data.item.nom + item.nom1 + index"
              class="align-td-item border-botom"
            >
              <td class="color">
                <div class="block-service-style">
                  <b-form-checkbox
                    v-model="item.check"
                    @change="checkAllVerifDetails(data.item, item)"
                    class="check-th-details ml-2"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="details-column-frais">
                <div class="block-service-style"></div>
              </td>
              <td class="installeur-column-frais">
                {{ item.nom1 + ' ' + item.prenom1 }}
              </td>

              <td class="item-details-style">
                {{ item.numero_dossier }}
              </td>
              <td class="item-details-style">
                {{ item.montant_final }}
              </td>
              <td class="item-details-style">
                <EditableInput
                  :editable="
                    cantUpdate($options.name) &&
                      !responsableAlteo &&
                      !isResponsableFiliale &&
                      item.externe_statue != 'paye' &&
                      item.externe_statue != 'annulee' &&
                      item.interne_statue != 'passive'
                  "
                  champName="super_regie"
                  editableType="select"
                  :optionsSelect="RegieList"
                  :item="item"
                  :value="item.super_regie"
                  :updateFunction="updateFraisBddCommericalCactus"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                />
              </td>
              <td class="item-details-style">
                {{ item.regie }}
              </td>
              <td class="item-details-style">
                {{ item.type_lead }}
              </td>
              <td
                class="item-details-style"
                v-if="computedShowDetailsColomn('CACTUS') && !responsableAlteo"
              >
                <b-form-checkbox
                  @change="handleChangeSwitch(item, item.acompte)"
                  :disabled="
                    !isSuperAdmin && !responsableAlteo && !responsableCactus
                  "
                  v-model="item.acompte"
                  name="check-button"
                  switch
                  :value="500"
                  :unchecked-value="0"
                >
                </b-form-checkbox>
              </td>
              <td class="item-details-style">
                {{ item.agent_commercial_terrain }}
              </td>
              <td class="item-details-style">
                {{ item.agent_commercial }}
              </td>
              <!-- globale   -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="computedShowDetailsColomn('CACTUS') && !responsableAlteo"
              >
                <div class="block-service-style">
                  <div
                    class="black-color-statue"
                    v-if="
                      !computedFactureVide(
                        item.montant_cactus,
                        item.cactus_statue
                      )
                    "
                  >
                    {{ item.cactus }}
                  </div>
                  <div v-else class="black-color-statue">-</div>
                </div>
              </td>
              <!-- bonification   -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="computedShowDetailsColomn('CACTUS') && !responsableAlteo"
              >
                <div class="block-service-style">
                  <div class="black-color-statue">
                    <EditableInputSuiviTh
                      v-if="
                        !computedFactureVide(
                          item.montant_cactus,
                          item.cactus_statue
                        )
                      "
                      :editable="
                        cantUpdate($options.name) &&
                          !isResponsableFiliale &&
                          item.cactus_statue != 'paye' &&
                          item.cactus_statue != 'annulee' &&
                          item.cactus_statue != 'passive'
                      "
                      champName="cactus"
                      :item="item"
                      :value="item.pu_cactus"
                      type="text"
                      :updateFunction="updateFraisBddCommericalCactus"
                      valueToShow="pu_cactus"
                      :showIconEdit="true"
                    />
                    <span v-else>-</span>
                  </div>
                </div>
              </td>
              <!-- CACTUS -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="computedShowDetailsColomn('CACTUS') && !responsableAlteo"
              >
                <div class="block-service-style">
                  <div
                    class="default-color-statue"
                    :class="{
                      'red-color': item.cactus_statue == 'facture non validée',
                      'green-color': item.cactus_statue == 'paye',
                      'blue-color': item.cactus_statue == 'passive',
                      'red-color montant-bare': item.cactus_statue == 'annulee'
                    }"
                    v-if="
                      !computedFactureVide(
                        item.montant_cactus,
                        item.cactus_statue
                      )
                    "
                  >
                    {{ item.montant_cactus }} €
                  </div>
                  <template-commercial-cactus
                    v-if="
                      !computedFactureVide(
                        item.montant_cactus,
                        item.cactus_statue
                      )
                    "
                    :componentName="$options.name"
                    :data="item"
                    type="CACTUS"
                    :year="filteryear"
                    :month="filterMonth"
                    :download="
                      item.cactus_statue == 'annulee'
                        ? false
                        : item.cactus_download
                    "
                    :id_facture="item.cactus_id"
                    :id_avoir="item.cactus_av_id"
                    :editable="
                      item.cactus_statue == 'paye' ||
                      item.cactus_statue == 'annulee' ||
                      item.cactus_statue == 'passive'
                        ? false
                        : true
                    "
                    :sendMailDate="item.cactus_email_sent_at"
                  />
                  <span v-else>-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                <paiement-column-component
                  v-if="
                    !computedFactureVide(
                      item.montant_cactus,
                      item.cactus_statue
                    )
                  "
                  :editable="
                    computedCreateLcrIFrais({
                      item: item,
                      column: 'cactus'
                    }) && item.cactus_download == true
                  "
                  champName="cactus"
                  :item="item"
                  :value="item.cactus_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                <EditableInput
                  v-if="
                    !computedFactureVide(
                      item.montant_cactus,
                      item.cactus_statue
                    )
                  "
                  :editable="
                    computedEditableStatutIFrais({
                      item: item,
                      column: 'cactus'
                    }) && item.cactus_download == true
                  "
                  champName="cactus_statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="{
                    item: item,
                    id_to_use: item.cactus_id
                  }"
                  :value="item.cactus_statue"
                  :updateFunction="updateStatueFraisVisionFelialeService"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                {{ computedFactureVideValue(item, 'cactus', 'cactus_date') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                {{ computedFactureVideValue(item, 'cactus', 'cactus_numero') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                <div class="block-service-style ">
                  <template
                    v-if="!computedFactureVide(item.cactus, item.cactus_statue)"
                    ><EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'cactus'
                        }) && item.cactus_download == true
                      "
                      champName="cactus_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.cactus_id
                      }"
                      :value="item.cactus_statue_installateur"
                      :updateFunction="
                        updateStatueInstallateurFraisVisionFelialeService
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.cactus_responsable_email"
                    />
                  </template>
                  <span v-else>-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'cactus',
                    'cactus_email_sent_at'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                {{ computedFactureVideValue(item, 'cactus', 'cactus_lot') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'CACTUS',
                    key: 'cactus'
                  }) && !responsableAlteo
                "
              >
                {{
                  computedFactureVideValue(item, 'cactus', 'cactus_av_numero')
                }}
              </td>
              <!-- LAV -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="computedShowDetailsColomn('LAV') && !responsableAlteo"
              >
                <div class="block-service-style">
                  <span
                    class="default-color-statue text-center"
                    :class="{
                      'red-color': item.externe_statue == 'facture non validée',
                      'green-color': item.externe_statue == 'paye',
                      'blue-color': item.externe_statue == 'passive',
                      'red-color montant-bare': item.externe_statue == 'annulee'
                    }"
                  >
                    <EditableInputSuiviTh
                      v-if="
                        !computedFactureVide(
                          item.montant_externe,
                          item.externe_statue
                        )
                      "
                      :editable="
                        cantUpdate($options.name) &&
                          !isResponsableFiliale &&
                          item.super_regie == 'EXTERNE' &&
                          item.externe_statue != 'paye' &&
                          item.externe_statue != 'annulee' &&
                          item.externe_statue != 'passive'
                      "
                      champName="externe"
                      :item="item"
                      :value="item.externe"
                      type="text"
                      :updateFunction="updateFraisBddCommericalCactus"
                      valueToShow="montant_externe"
                      :showIconEdit="true"
                    />
                    <span v-else>-</span>
                  </span>

                  <template-commercial-cactus
                    v-if="
                      !computedFactureVide(
                        item.montant_externe,
                        item.externe_statue
                      )
                    "
                    :componentName="$options.name"
                    :data="item"
                    type="LAV"
                    :year="filteryear"
                    :month="filterMonth"
                    :download="
                      item.externe_statue == 'annulee'
                        ? false
                        : item.externe_download
                    "
                    :id_facture="item.externe_id"
                    :id_avoir="item.externe_av_id"
                    :editable="
                      item.externe_statue == 'paye' ||
                      item.externe_statue == 'annulee' ||
                      item.externe_statue == 'passive'
                        ? false
                        : true
                    "
                    :permission="item.super_regie == 'EXTERNE' ? true : false"
                    :sendMailDate="item.externe_email_sent_at"
                  />
                </div>
              </td>

              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                <paiement-column-component
                  v-if="
                    computedShowDetailsColomn({
                      value: 'LAV',
                      key: 'montant_externe'
                    }) && !responsableAlteo
                  "
                  :editable="
                    computedCreateLcrIFrais({
                      item: item,
                      column: 'externe'
                    })
                  "
                  champName="externe"
                  :item="item"
                  :value="item.externe_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
                <span v-else class="text-center">-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                <EditableInput
                  v-if="
                    !computedFactureVide(
                      item.montant_externe,
                      item.externe_statue
                    ) &&
                      item.externe_download == true &&
                      item.super_regie == 'EXTERNE'
                  "
                  :editable="
                    computedEditableStatutIFrais({
                      item: item,
                      column: 'externe'
                    })
                  "
                  champName="externe_statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="{
                    item: item,
                    id_to_use: item.externe_id
                  }"
                  :value="item.externe_statue"
                  :updateFunction="updateFraisBddCommericalCactusStatut"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <span v-else class="text-center">-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                {{ computedFactureVideValue(item, 'externe', 'externe_date') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                {{
                  computedFactureVideValue(item, 'externe', 'externe_numero')
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                <div class="block-service-style text-center">
                  <template
                    v-if="
                      !computedFactureVide(
                        item.montant_externe,
                        item.externe_statue
                      )
                    "
                  >
                    <EditableInput
                      v-if="
                        !computedFactureVide(
                          item.montant_externe,
                          item.externe_statue
                        ) &&
                          item.externe_download == true &&
                          item.super_regie == 'EXTERNE'
                      "
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'externe'
                        })
                      "
                      champName="externe_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.externe_id
                      }"
                      :value="item.externe_statue_installateur"
                      :updateFunction="
                        updateFraisBddCommericalCactusStatutInstallateur
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"/>
                    <icon-info-component :item="item.externe_responsable_email"
                  /></template>

                  <span v-else class="text-center">-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'externe',
                    'externe_email_sent_at'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                {{ computedFactureVideValue(item, 'externe', 'externe_lot') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'LAV',
                    key: 'montant_externe'
                  }) && !responsableAlteo
                "
              >
                {{
                  computedFactureVideValue(item, 'externe', 'externe_av_numero')
                }}
              </td>
              <!-- ALTEO FRANCE Supervision -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="computedShowDetailsColomn('ALTEO FRANCE Supervision')"
              >
                <div class="block-service-style">
                  <span
                    class="default-color-statue text-center"
                    :class="{
                      'red-color': item.alteo_statue == 'facture non validée',
                      'green-color': item.alteo_statue == 'paye',
                      'red-color montant-bare': item.alteo_statue == 'annulee',
                      'blue-color': item.alteo_statue == 'passive'
                    }"
                  >
                    <EditableInputSuiviTh
                      v-if="
                        !computedFactureVide(
                          item.montant_alteo1,
                          item.alteo_statue
                        )
                      "
                      :editable="
                        cantUpdate($options.name) &&
                          !isResponsableFiliale &&
                          item.alteo_statue != 'paye' &&
                          item.alteo_statue != 'annulee' &&
                          item.alteo_statue != 'passive'
                      "
                      champName="alteo1"
                      :item="item"
                      :value="item.alteo1"
                      type="text"
                      :updateFunction="updateFraisBddCommericalCactus"
                      valueToShow="montant_alteo1"
                      :showIconEdit="true"
                    />
                    <span v-else>-</span>
                  </span>
                  <template-commercial-cactus
                    v-if="
                      !computedFactureVide(
                        item.montant_alteo1,
                        item.alteo_statue
                      )
                    "
                    :componentName="$options.name"
                    :data="item"
                    type="AFSP"
                    :year="filteryear"
                    :month="filterMonth"
                    :download="
                      item.alteo_statue == 'annulee'
                        ? false
                        : item.alteo_download
                    "
                    :id_facture="item.alteo_id"
                    :id_avoir="item.alteo_av_id"
                    :editable="
                      item.alteo_statue == 'paye' ||
                      item.alteo_statue == 'annulee' ||
                      item.alteo_statue == 'passive'
                        ? false
                        : true
                    "
                    :sendMailDate="item.alteo_email_sent_at"
                  />
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                <paiement-column-component
                  v-if="
                    !computedFactureVide(item.montant_alteo1, item.alteo_statue)
                  "
                  :editable="
                    computedCreateLcrIFrais({
                      item: item,
                      column: 'alteo'
                    }) && item.alteo_download == true
                  "
                  champName="alteo"
                  :item="item"
                  :value="item.alteo_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                <EditableInput
                  v-if="
                    !computedFactureVide(item.montant_alteo1, item.alteo_statue)
                  "
                  :editable="
                    computedEditableStatutIFrais({
                      item: item,
                      column: 'alteo'
                    }) && item.alteo_download == true
                  "
                  champName="alteo_statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="{
                    item: item,
                    id_to_use: item.alteo_id
                  }"
                  :value="item.alteo_statue"
                  :updateFunction="updateFraisBddCommericalCactusStatut"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'alteo', 'alteo_date') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'alteo', 'alteo_numero') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                <div class="block-service-style">
                  <template
                    v-if="
                      !computedFactureVide(
                        item.montant_alteo1,
                        item.alteo_statue
                      )
                    "
                    ><EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'alteo'
                        }) && item.alteo_download == true
                      "
                      champName="alteo_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.alteo_id
                      }"
                      :value="item.alteo_statue_installateur"
                      :updateFunction="
                        updateFraisBddCommericalCactusStatutInstallateur
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"/>
                    <icon-info-component :item="item.alteo_responsable_email"
                  /></template>
                  <span v-else>-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                {{
                  computedFactureVideValue(item, 'alteo', 'alteo_email_sent_at')
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'alteo', 'alteo_lot') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Supervision',
                    key: 'montant_alteo'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'alteo', 'alteo_av_numero') }}
              </td>
              <!-- ALTEO FRANCE Support Commercial -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn('ALTEO FRANCE Support Commercial')
                "
              >
                <div class="block-service-style">
                  <span
                    class="default-color-statue text-center"
                    :class="{
                      'red-color':
                        item.commercial_statue == 'facture non validée',
                      'green-color': item.commercial_statue == 'paye',
                      'blue-color': item.commercial_statue == 'passive',
                      'red-color montant-bare':
                        item.commercial_statue == 'annulee'
                    }"
                  >
                    <EditableInputSuiviTh
                      v-if="
                        !computedFactureVide(
                          item.montant_commercial,
                          item.commercial_statue
                        )
                      "
                      :editable="
                        cantUpdate($options.name) &&
                          !isResponsableFiliale &&
                          item.commercial_statue != 'paye' &&
                          item.commercial_statue != 'annulee' &&
                          item.commercial_statue != 'passive'
                      "
                      champName="commercial"
                      :item="item"
                      :value="item.commercial"
                      type="text"
                      :updateFunction="updateFraisBddCommericalCactus"
                      valueToShow="montant_commercial"
                      :showIconEdit="true"
                    />
                    <span v-else>-</span>
                  </span>
                  <template-commercial-cactus
                    v-if="
                      !computedFactureVide(
                        item.montant_commercial,
                        item.commercial_statue
                      )
                    "
                    :componentName="$options.name"
                    :data="item"
                    type="AFCOMMERCIAL"
                    :year="filteryear"
                    :month="filterMonth"
                    :download="item.commercial_download"
                    :id_facture="item.commercial_id"
                    :id_avoir="item.commercial_av_id"
                    :editable="
                      item.commercial_statue == 'paye' ||
                      item.commercial_statue == 'annulee' ||
                      item.commercial_statue == 'passive'
                        ? false
                        : true
                    "
                    :sendMailDate="item.commercial_email_sent_at"
                  />
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                <paiement-column-component
                  v-if="
                    !computedFactureVide(
                      item.montant_commercial,
                      item.commercial_statue
                    )
                  "
                  :editable="
                    computedCreateLcrIFrais({
                      item: item,
                      column: 'commercial'
                    }) && item.commercial_download == true
                  "
                  champName="commercial"
                  :item="item"
                  :value="item.commercial_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                <EditableInput
                  v-if="
                    !computedFactureVide(
                      item.montant_commercial,
                      item.commercial_statue
                    )
                  "
                  :editable="
                    computedEditableStatutIFrais({
                      item: item,
                      column: 'commercial'
                    }) && item.commercial_download == true
                  "
                  champName="commercial_statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="{
                    item: item,
                    id_to_use: item.commercial_id
                  }"
                  :value="item.commercial_statue"
                  :updateFunction="updateFraisBddCommericalCactusStatut"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'commercial',
                    'commercial_date'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'commercial',
                    'commercial_numero'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                <div class="block-service-style">
                  <template
                    v-if="
                      !computedFactureVide(
                        item.montant_commercial,
                        item.commercial_statue
                      )
                    "
                  >
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'commercial'
                        }) && item.commercial_download == true
                      "
                      champName="commercial_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.commercial_id
                      }"
                      :value="item.commercial_statue_installateur"
                      :updateFunction="
                        updateFraisBddCommericalCactusStatutInstallateur
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"/>
                    <icon-info-component
                      :item="item.commercial_responsable_email"
                  /></template>
                  <span v-else>-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'commercial',
                    'commercial_email_sent_at'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                {{
                  computedFactureVideValue(item, 'commercial', 'commercial_lot')
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'commercial',
                    'commercial_av_numero'
                  )
                }}
              </td>
              <!-- ALTEO FRANCE Régie Commerciale INTERNE -->
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn(
                    'ALTEO FRANCE Régie Commerciale INTERNE'
                  )
                "
              >
                <div class="block-service-style">
                  <span
                    class="default-color-statue text-center"
                    :class="{
                      'red-color': item.interne_statue == 'facture non validée',
                      'green-color': item.interne_statue == 'paye',
                      'blue-color': item.interne_statue == 'passive',
                      'red-color montant-bare': item.interne_statue == 'annulee'
                    }"
                  >
                    <EditableInputSuiviTh
                      v-if="
                        !computedFactureVide(
                          item.montant_interne,
                          item.interne_statue
                        )
                      "
                      :editable="
                        cantUpdate($options.name) &&
                          !isResponsableFiliale &&
                          item.super_regie == 'INTERNE' &&
                          item.interne_statue != 'paye' &&
                          item.interne_statue != 'annulee' &&
                          item.interne_statue != 'passive'
                      "
                      champName="interne"
                      :item="item"
                      :value="item.interne"
                      type="text"
                      :updateFunction="updateFraisBddCommericalCactus"
                      valueToShow="montant_interne"
                      :showIconEdit="true"
                    />
                    <span v-else>-</span>
                  </span>

                  <template-commercial-cactus
                    v-if="
                      !computedFactureVide(
                        item.montant_interne,
                        item.interne_statue
                      )
                    "
                    :componentName="$options.name"
                    :data="item"
                    type="AFCONFIRMATION"
                    :year="filteryear"
                    :month="filterMonth"
                    :download="
                      item.interne_statue == 'annulee'
                        ? false
                        : item.interne_download
                    "
                    :id_facture="item.interne_id"
                    :id_avoir="item.interne_av_id"
                    :editable="
                      item.interne_statue == 'paye' ||
                      item.interne_statue == 'annulee' ||
                      item.interne_statue == 'passive'
                        ? false
                        : true
                    "
                    :permission="item.super_regie == 'INTERNE' ? true : false"
                    :sendMailDate="item.interne_email_sent_at"
                  />
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                <paiement-column-component
                  v-if="
                    !computedFactureVide(
                      item.montant_interne,
                      item.interne_statue
                    )
                  "
                  :editable="
                    computedCreateLcrIFrais({
                      item: item,
                      column: 'interne'
                    }) &&
                      item.interne_download == true &&
                      item.super_regie == 'INTERNE'
                  "
                  champName="interne"
                  :item="item"
                  :value="item.interne_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
                <span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                <EditableInput
                  v-if="
                    !computedFactureVide(
                      item.montant_interne,
                      item.interne_statue
                    )
                  "
                  :editable="
                    computedEditableStatutIFrais({
                      item: item,
                      column: 'interne'
                    }) &&
                      item.interne_download == true &&
                      item.super_regie == 'INTERNE'
                  "
                  champName="interne_statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="{
                    item: item,
                    id_to_use: item.interne_id
                  }"
                  :value="item.interne_statue"
                  :updateFunction="updateFraisBddCommericalCactusStatut"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                /><span v-else>-</span>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'interne', 'interne_date') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                {{
                  computedFactureVideValue(item, 'interne', 'interne_numero')
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                <div class="block-service-style">
                  <template
                    v-if="
                      !computedFactureVide(
                        item.montant_interne,
                        item.interne_statue
                      )
                    "
                  >
                    <EditableInput
                      :editable="
                        computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'interne'
                        }) &&
                          item.interne_download == true &&
                          item.super_regie == 'INTERNE'
                      "
                      champName="interne_statue_installateur"
                      editableType="select"
                      :optionsSelect="computedValidationListInstallateur"
                      :item="{
                        item: item,
                        id_to_use: item.interne_id
                      }"
                      :value="item.interne_statue_installateur"
                      :updateFunction="
                        updateFraisBddCommericalCactusStatutInstallateur
                      "
                      label="value"
                      :positionSelect="true"
                      formate="regieFacture"
                      :showIconEdit="true"
                    />
                    <icon-info-component
                      :item="item.interne_responsable_email"
                    />
                  </template>
                  <span v-else>-</span>
                </div>
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                {{
                  computedFactureVideValue(
                    item,
                    'interne',
                    'interne_email_sent_at'
                  )
                }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                {{ computedFactureVideValue(item, 'interne', 'interne_lot') }}
              </td>
              <td
                class=" item-details-style justify-content-sb-frais-commercial-cactus"
                v-if="
                  computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                "
              >
                {{
                  computedFactureVideValue(item, 'interne', 'interne_av_numero')
                }}
              </td>
            </span></template
          >
        </b-table>
        <div class=" footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getfraisBddCommericalCactusDataTotal"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
      <div v-if="computedTotal > 0" class="style-somme-th-ttc">
        <p class="m-2">
          Total ht:
          {{ computedTotal.toFixed(2).toLocaleString('en-US') }}€
          {{ '   ' }}Total ttc:

          {{ computedTotalTTC.toFixed(2).toLocaleString('en-US') }}€
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';
import EditableInputSuiviTh from '../../component/EditableInputSuiviTh.vue';
import GlobalValidationCactus from './component/commercialCactusFrais/globalValidationCactus.vue';
import ExtractionCactus from './component/commercialCactusFrais/extractionCactus.vue';
import templateCommercialCactus from './component/commercialCactusFrais/templateCommercialCactus.vue';
import sendMailCommercialCactus from './component/commercialCactusFrais/sendMailCommercialCactus.vue';
import ExtractionCactusInvalid from './component/commercialCactusFrais/extractionCactusInvalid.vue';

export default {
  name: 'Gestion-Commercial-Cactus',
  data() {
    return {
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      sommeSelected: 0,
      filterStatut: null,
      flashMessageId: null,
      filterWeek: null,
      filterFiliale: null,
      filterDepot: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterType: null,
      filterSuperRegie: null,
      filterRegie: null,
      fields: [
        {
          key: 'check_all',
          label: '',
          views: true,
          tdClass: 'color',
          thClass: 'th-color',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais',
          views: true,
          for: 'all'
        },

        {
          key: 'installeur',
          label: 'Filiale Installateur',
          views: true,
          thClass: 'th-installeur-column-frais',
          tdClass: 'installeur-column-frais',
          for: 'all'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'montant_final',
          label: 'Montant min',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },

        {
          key: 'super_regie',
          label: 'Super régie',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'regie',
          label: 'Régie',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'type_lead',
          label: 'Type lead',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'acompte',
          label: 'Acompte',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'cactus'
        },
        {
          key: 'agent_commercial_terrain',
          label: 'Agent commercial terrain',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'agent_commercial',
          label: 'Agent commercial',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'coef_cactus',
          label: 'Coef globale',
          views: true,
          details: true,
          thClass: 'bg-color-th-commercial ',
          for: 'cactus'
        },
        {
          key: 'bonification_cactus',
          label: 'Bonification cactus',
          views: true,
          details: true,
          thClass: 'bg-color-th-commercial ',
          for: 'cactus'
        },
        {
          key: 'cactus',
          label: 'Gestion Commercial (CACTUS)',
          views: true,
          details: true,
          thClass: 'bg-color-th-commercial ',
          for: 'cactus'
        },
        {
          key: 'cactus_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-commercial ',
          for: 'cactus'
        },
        {
          key: 'cactus_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-commercial ',
          for: 'cactus'
        },

        {
          key: 'cactus_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        {
          key: 'cactus_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        {
          key: 'cactus_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        {
          key: 'cactus_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        {
          key: 'cactus_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        {
          key: 'cactus_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-commercial  ',
          for: 'cactus'
        },
        //
        {
          key: 'montant_externe',
          label: 'LAV Régie Commerciale EXTERNE',
          views: true,
          details: true,
          thClass: 'bg-color-th-comptabilite  ',
          for: 'externe'
        },
        // {
        //   key: 'externe',
        //   label: 'Coef LAV Régie Commerciale EXTERNE',
        //   views: false,
        //   thClass: 'bg-color-th-comptabilite  ',
        //   for: 'externe'
        // },
        {
          key: 'externe_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        {
          key: 'externe_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-comptabilite ',
          for: 'externe'
        },
        //
        {
          key: 'montant_alteo',
          label: 'ALTEO FRANCE Supervision',
          views: true,
          details: true,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        // {
        //   key: 'montant_alteo1',
        //   label: 'ALTEO FRANCE Supervision Confirmation',
        //   views: false,
        //   thClass: 'bg-color-th-bureau-etude ',
        //   for: 'alteo'
        // },
        // {
        //   key: 'montant_alteo2',
        //   label: 'ALTEO FRANCE Supervision Commercial',
        //   views: false,
        //   thClass: 'bg-color-th-bureau-etude ',
        //   for: 'alteo'
        // },
        {
          key: 'alteo_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        {
          key: 'alteo_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-bureau-etude ',
          for: 'alteo'
        },
        //
        {
          key: 'montant_commercial',
          label: 'ALTEO FRANCE Support Commercial ',
          views: true,
          details: true,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        // {
        //   key: 'commercial',
        //   label: 'Coef ALTEO FRANCE Support Commercial',
        //   views: false,
        //   thClass: 'bg-color-th-marie ',
        //   for: 'commercial'
        // },
        {
          key: 'commercial_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        {
          key: 'commercial_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'commercial'
        },
        //
        {
          key: 'montant_interne',
          label: 'ALTEO FRANCE Régie Commerciale INTERNE',
          views: true,
          details: true,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        // {
        //   key: 'interne',
        //   label: 'Coef ALTEO FRANCE Régie Commerciale INTERNE',
        //   views: false,
        //   thClass: 'bg-color-th-maintenance-pac ',
        //   for: 'interne'
        // },
        {
          key: 'interne_type_paiement',
          label: 'Type de paiement',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_email_sent_at',
          label: "Date d'envoie",
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_lot',
          label: 'Numéro de lot',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        },
        {
          key: 'interne_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'interne'
        }
      ],
      RegieList: [
        { value: 'EXTERNE', full_name: 'EXTERNE' },
        { value: 'INTERNE', full_name: 'INTERNE' }
      ],
      listType: [
        { value: 'CACTUS', text: 'CACTUS' },
        { value: 'LAV', text: 'LAV' },
        {
          value: 'ALTEO FRANCE Régie Commerciale INTERNE',
          text: 'ALTEO FRANCE Régie Commerciale INTERNE'
        },
        { value: 'ALTEO FRANCE Supervision', text: 'ALTEO FRANCE Supervision' },
        {
          value: 'ALTEO FRANCE Support Commercial',
          text: 'ALTEO FRANCE Support Commercial'
        }
      ],
      sortBy: 'name',
      searchValue: null,
      checkAll: false,
      typeArray: ['commercial']
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getLoadingFraisBddCommericalCactus',
      'getFraisBddCommericalCactusData',
      'cantValid',
      'cantUpdate',
      'getTotalfraisBddCommericalCactusData',
      'getErrorFraisBddCommericalCactus',
      'cantCreate',
      'getUserData',
      'cantRefresh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'cantSend',
      'getLoadingMail',
      'getFraisBddCommericalCactusData',
      'cantExport',
      'isResponsableFiliale',
      'getRegieOnlineInCactusFrais',
      'getfraisBddCommericalCactusDataTotal'
    ]),
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        search: this.searchValue,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType,
        regie: this.filterRegie,
        super_regie: this.filterSuperRegie
      };
    },
    computedEditableStatutIFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdmin) {
            return true;
          }
          if (
            this.cantUpdate(this.$options.name) &&
            !this.isSuperAdmin &&
            !this.isResponsableFiliale &&
            data.item[data.column + '_statue'] != 'paye' &&
            data.item[data.column + '_statue'] != 'annulee' &&
            data.item[data.column + '_statue'] != 'passive'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedCreateLcrIFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdmin) {
            return true;
          }
          if (
            this.cantCreate(this.$options.name) &&
            !this.isSuperAdmin &&
            !this.isResponsableFiliale &&
            data.item[data.column + '_statue'] != 'paye' &&
            data.item[data.column + '_statue'] != 'annulee' &&
            data.item[data.column + '_statue'] != 'passive'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedEditableStatutInstallateurFrais: function() {
      return function(data) {
        if (data) {
          if (
            this.cantUpdate(this.$options.name) &&
            (this.isResponsableFiliale || this.isSuperAdmin) &&
            data.item[data.column + '_statue'] != 'paye' &&
            data.item[data.column + '_statue'] != 'annulee' &&
            data.item[data.column + '_statue'] != 'passive' &&
            data.item[data.column + '_statue_installateur'] != 'paye'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedCheckedRows() {
      return this.getFraisBddCommericalCactusData?.filter(k => {
        return k.projects.some(e => e.check == true);
      });
    },
    computedValidationList() {
      return [
        { value: 'passive', full_name: 'Facture passive' },
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    isSuperAdmin() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin'
      );
    },
    computedRole() {
      return !(
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_artisans'
      );
    },
    computedListType() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_acteo'
      ) {
        return this.listType.filter(
          item => item.value != 'LAV' && item.value != 'CACTUS'
        );
      }
      return this.listType;
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedFraisBddCommericalCactus() {
      return this.getFraisBddCommericalCactusData;
    },
    computedFields() {
      switch (this.filterType) {
        case 'LAV':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'externe'
          );
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'interne'
          );

        case 'ALTEO FRANCE Supervision':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'alteo'
          );
        case 'ALTEO FRANCE Support Commercial':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'commercial'
          );
        case 'CACTUS':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'cactus'
          );
        default:
          if (
            this.getUserData &&
            this.getUserData.role &&
            this.getUserData.role == 'responsable_acteo'
          ) {
            return this.fields.filter(
              item =>
                item.views == true &&
                item.for != 'externe' &&
                item.for != 'cactus'
            );
          }
          return this.fields.filter(item => item.views == true);
      }
    },
    responsableAlteo() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_acteo'
      );
    },
    responsableCactus() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_cactus'
      );
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'montant_alteo' ||
          data == 'montant_externe' ||
          data == 'montant_commercial' ||
          data == 'montant_interne' ||
          data == 'cactus'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },

    computedShowDetailsColomn() {
      return function(data) {
        if (data && data.key) {
          if (
            (this.filterType == null &&
              this.fields.find(
                item => item.key == data.key && item.details == false
              )) ||
            this.filterType == data.value
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.filterType == null || this.filterType == data) {
            return true;
          } else {
            return false;
          }
        }
      };
    },
    computedTotal() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                      // .replace(' ', '')
                      // .replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedTotalTTC() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                      // .replace(' ', '')
                      // .replace(',', '.')
                    ) *
                      1.2 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedColumn() {
      switch (this.filterType) {
        case 'LAV':
          return 'montant_externe';
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return 'montant_interne';
        case 'ALTEO FRANCE Supervision':
          return 'montant_alteo';
        case 'ALTEO FRANCE Support Commercial':
          return 'commercial';
        case 'CACTUS':
          return 'montant_cactus';
        default:
          return this.filterType;
      }
    },
    computedFactureVide() {
      return function(montant, statue) {
        if (
          statue == 'facture non validée' &&
          (montant == '0.00' ||
            montant == '0,00' ||
            montant == '0' ||
            montant == 0)
        ) {
          return true;
        }
        return false;
      };
    },
    computedFactureVideValue() {
      return function(item, column, type) {
        if (item && column && type) {
          let statue = column + '_statue';
          let montant = 'montant_' + column;
          if (column == 'alteo') {
            montant = 'montant_' + column + '1';
          }

          if (
            item[statue] == 'facture non validée' &&
            (item[montant] == '0.00' || item[montant] == '0,00')
          ) {
            return '-';
          }
          if (type == 'montant') {
            return item[montant] + ' €';
          } else {
            return item[type];
          }
        }
      };
    },
    computedType() {
      switch (this.filterType) {
        case 'LAV':
          return 'LAV';
        case 'ALTEO FRANCE Régie Commerciale INTERNE':
          return 'AFCONFIRMATION';
        case 'ALTEO FRANCE Supervision':
          return 'AFSP ';
        case 'ALTEO FRANCE Support Commercial':
          return 'AFCOMMERCIAL';
        default:
          return this.filterType;
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllFraisBddCommericalCactus',
      'updateFraisBddCommericalCactus',
      'updateFraisBddCommericalCactusStatut',
      'refreshDataBddCommericalCactusCommercialCactus',
      'updateStatueFraisVisionFelialeService',
      'downloadExtraitInstalleurAction',
      'downloadExtraitSupportServiceAction',
      'updateStatueInstallateurFraisVisionFelialeService',
      'updateFraisBddCommericalCactusStatutInstallateur',
      'updateInterface',
      'getOnlineRegieForCactusFrais',
      'updateTauxFraisInInterface',
      'updattypePaiementFrais',
      'getDataForRowFraisC'
    ]),
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowFraisC({
          item: data.item,
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          week: this.filterWeek,
          depot: this.filterDepot,
          statue: this.filterStatut,
          type: this.computedType,
          regie: this.filterRegie,
          super_regie: this.filterSuperRegie,
          page: this.page,
          perPage: this.perPage
        });
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    handleChangeSwitch(item, value) {
      this.updateFraisBddCommericalCactus({
        value: value,
        row: item,
        column: 'acompte'
      });
    },
    rowClass(item, rowType) {
      if (rowType === 'row-details') {
        return 'subLigneEnCourDeValidation';
      }
      return 'ligneEnCourDeValidation';
    },
    handleDownloadExtraitInstalleur(item) {
      if (this.filterMonth != null && this.filterType == null) {
        this.$confirm(
          '',
          'Êtes-vous sur de vouloir télecharger tous les extraits pour ' +
            item +
            ' ?',
          'warning'
        )
          .then(() => {
            this.downloadExtraitInstalleurAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item,
              type: this.typeArray
            });
          })
          .catch(() => {});
      }
    },
    handleDownloadExtraitSupportService(item) {
      if (this.filterMonth != null) {
        this.downloadExtraitSupportServiceAction({
          year: this.filteryear,
          month: this.filterMonth,
          filiale: item.item.name,
          type: item.type
        });
      }
    },
    checkRole() {
      if (this.getUserData && this.getUserData.role) {
        switch (this.getUserData.role) {
          case 'responsable_artisans':
            this.filterType = 'LAV';
            break;
          // case 'responsable_filiale':
          //   this.filterFiliale = this.getUserData.filiale_name;
        }
      }
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.filterSuperRegie = null;
      this.filterRegie = null;

      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      this.filterSuperRegie = null;
      this.filterRegie = null;

      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      this.filterSuperRegie = null;
      this.filterRegie = null;

      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.filterSuperRegie = null;
      this.filterRegie = null;

      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleChangeDepot() {
      this.filterSuperRegie = null;
      this.filterRegie = null;

      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handlechangeFilialeSupport() {
      this.sommeSelected = 0;
      this.filterStatut = null;
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleFilter() {
      this.getAllFraisBddCommericalCactus({
        year: this.filteryear,
        month: this.filterMonth,
        search: this.searchValue,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType,
        regie: this.filterRegie,
        super_regie: this.filterSuperRegie,
        page: this.page,
        perPage: this.perPage
      });
      this.getOnlineRegieForCactusFraisFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    handleFilterSuperregie() {
      this.filterRegie = null;
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    getOnlineRegieForCactusFraisFilter() {
      this.getOnlineRegieForCactusFrais({
        year: this.filteryear,
        month: this.filterMonth,
        search: this.searchValue,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.computedType,
        regie: this.filterRegie,
        super_regie: this.filterSuperRegie
      });
    },
    reloadData() {
      this.checkAll = false;
      this.filterStatut = null;
      this.handleFilter();
      this.sommeSelected = 0;
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageInterneCommercialCactus();
    },
    setVisibilityFieldsHeader(key) {
      if (this.computedHeaderVisibility(key)) {
        this.fields.map(item => {
          if (key == 'cactus') {
            if (
              item.key == key + '_type_paiement' ||
              item.key == key + '_statue' ||
              item.key == key + '_date' ||
              item.key == key + '_numero' ||
              item.key == key + '_av_numero' ||
              item.key == key + '_statue_installateur' ||
              item.key == key + '_email_sent_at' ||
              item.key == key + '_lot'
            ) {
              item.views = !item.views;
            }
          } else {
            if (
              item.key == key.split('_')[1] + '_type_paiement' ||
              item.key == key.split('_')[1] + '_statue' ||
              item.key == key.split('_')[1] + '_date' ||
              item.key == key.split('_')[1] + '_numero' ||
              item.key == key.split('_')[1] + '_av_numero' ||
              item.key == key.split('_')[1] + '_statue_installateur' ||
              item.key == key.split('_')[1] + '_email_sent_at' ||
              item.key == key.split('_')[1] + '_lot'
            ) {
              item.views = !item.views;
            }
          }
          if (item.key == key) {
            item.details = !item.details;
          }
          if (
            key == 'montant_alteo' &&
            (item.key == 'montant_alteo1' || item.key == 'montant_alteo2')
          ) {
            item.views = !item.views;
          }
        });
      }
    },
    async refreshBddCommericalCactus() {
      const response = await this.refreshDataBddCommericalCactusCommercialCactus(
        {
          year: this.filteryear,
          month: this.filterMonth
        }
      );
      if (response) {
        this.handleFilter();
      }
    },
    checkAllFunction() {
      this.getFraisBddCommericalCactusData.map(item => {
        item.check = this.checkAll;
        item.projects.map(itemD => (itemD.check = this.checkAll));
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemD => (itemD.check = item.check));
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    setLocalStorageInterneCommercialCactus() {
      localStorage.setItem(
        'comercial-cactus',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          week: this.filterWeek,
          filiale: this.filterFiliale,
          depot: this.filterDepot,
          statue: this.filterStatut,
          type: this.computedType,
          regie: this.filterRegie,
          super_regie: this.filterSuperRegie
        })
      );
    }
  },
  components: {
    EditableInput,
    EditableInputSuiviTh,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    GlobalValidationCactus,
    ExtractionCactus,
    templateCommercialCactus,
    sendMailCommercialCactus,
    validationStatueInstallateur: () =>
      import('./component/ValidationStatueInstallateur.vue'),
    iconInfoComponent: () => import('./component/iconInfoComponent.vue'),
    columnHeaderWithToolTip: () =>
      import('./component/headerTable/columnHeaderWithToolTip.vue'),
    generationFactureAvoirFrais: () =>
      import('./component/generationFactureAvoirFrais.vue'),
    paiementColumnComponent: () =>
      import('./component/paiementColumnComponent.vue')

    // ExtractionCactusInvalid
  },
  async mounted() {
    this.updateInterface('cactus');
    var courantYear = moment().year();
    this.filteryear = courantYear;

    if (localStorage.getItem('comercial-cactus')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('comercial-cactus')) {
      this.filterMonth = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).month;
      this.filterWeek = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).week;
      this.searchValue = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).search;
      this.filterStatut = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).statue;
      this.filterDepot = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).depot;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).filiale;
      this.filterType = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).type;
      this.filterRegie = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).regie;
      this.filterSuperRegie = JSON.parse(
        localStorage.getItem('comercial-cactus')
      ).super_regie;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageInterneCommercialCactus();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.getOnlineRegieForCactusFraisFilter();
    this.checkRole();
    this.handleFilter();
  },
  watch: {
    getErrorFraisBddCommericalCactus: function() {
      if (this.getErrorFraisBddCommericalCactus != null) {
        const objectFlash = {
          blockClass: 'custom-block-class',
          contentClass: 'custom-content-class',
          message: ''
        };
        if (Array.isArray(this.getErrorFraisBddCommericalCactus)) {
          for (let e in this.getErrorFraisBddCommericalCactus) {
            objectFlash.message =
              objectFlash.message +
              ' ' +
              this.getErrorFraisBddCommericalCactus[e];
          }
        } else {
          objectFlash.message = this.getErrorFraisBddCommericalCactus;
        }
        this.flashMessageId = this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          blockClass: objectFlash.blockClass,
          contentClass: objectFlash.contentClass,
          message: objectFlash.message
        });
      } else {
        this.flashMessage.deleteMessage(this.flashMessageId);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.flashMessage.deleteMessage(this.flashMessageId);
    next();
  }
};
</script>
<style scoped lang="scss">
.frais-commercial-cactus {
  .button-export-style {
    padding-left: 1.5px;
    padding-right: 1.7px;
    img {
      width: 24px;
    }
  }
  .entete {
    padding: 5px 7px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableFraisBddCommericalCactus {
      max-height: calc(100vh - 220px) !important;
      height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
    .icon-plus {
      margin-right: 7px;
      color: #ffff;
      font-size: 10px;
      &:hover {
        color: #8d8cb7;
      }
    }
  }
  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableFraisBddCommericalCactus {
  td,
  th {
    min-width: 150px;
    width: 150px;
  }
  td {
    line-height: 10px;
  }
  span {
    cursor: pointer;
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .icon-plus-h {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .item-details-style {
    min-width: 100px;
    width: 150px;
    border-right: 1px solid #fff;
  }
}
.color {
  border-left: none !important;
}
.table-rapport-style .customTableFraisBddCommericalCactus {
  td,
  th {
    font-size: 9px;
  }
  .justify-content-sb-frais-commercial-cactus {
    display: block !important;
    line-height: 11px !important;
  }
}
</style>
